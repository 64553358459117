import type { KarrotLocalCountryCode } from "@karrotmarket-com/core";

function parseDecimal(price: string, regex: RegExp) {
  const match = price.match(regex);

  if (!match) {
    return null;
  }

  const [, integer, decimal] = match;

  return [integer, decimal];
}

function withCommaForInteger(integerPartPrice: string) {
  return integerPartPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function cleanUpZeroDecimal(price: string) {
  const parsed = parseDecimal(price, /^(\d+)(\.\d*?)0+$/);

  if (!parsed) {
    return price;
  }

  const [integer, decimal] = parsed;

  if (decimal === ".") {
    return integer;
  }

  return integer + decimal;
}

function makeFormattedPrice(price: string) {
  const cleanZeroDecimal = cleanUpZeroDecimal(price);
  const parsed = parseDecimal(cleanZeroDecimal, /^(\d+)(\.\d*?)$/);

  if (!parsed) {
    return withCommaForInteger(cleanZeroDecimal);
  }

  const [integer, decimalWithDecimalPoint] = parsed;

  if (decimalWithDecimalPoint.length > 2) {
    return `${withCommaForInteger(integer)}${decimalWithDecimalPoint}`;
  }

  return `${withCommaForInteger(integer)}${decimalWithDecimalPoint}0`;
}

export const noPriceLabelMap: Record<KarrotLocalCountryCode, string> = {
  kr: "가격 없음",
  jp: "価格なし",
  uk: "No Price",
  ca: "No Price",
  us: "No Price",
};

const zeroPriceLabelMap: Record<KarrotLocalCountryCode, string> = {
  kr: "나눔",
  jp: "譲ります(0円)",
  uk: "Free",
  ca: "Free",
  us: "Free",
};

export default function getFleamarketArticlePrice(
  countryCode: KarrotLocalCountryCode,
  price?: string | null,
) {
  if (typeof price !== "string") {
    return noPriceLabelMap[countryCode];
  }

  if (price === "0.0") {
    return zeroPriceLabelMap[countryCode];
  }

  const formattedPrice = makeFormattedPrice(price);

  switch (countryCode) {
    case "kr":
      return `${formattedPrice}원`;
    case "jp":
      return `${formattedPrice}円`;
    case "uk":
      return `£${formattedPrice}`;
    case "ca":
      return `$${formattedPrice}`;
    case "us":
      return `$${formattedPrice}`;
    default:
      throw new Error(
        "getFleaMarketArticlePrice(): Not supported country code",
      );
  }
}
